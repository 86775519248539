import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IAuthInfo, IDecodeJWT, IUser } from '../models/auth';
import { IChangePassword } from '../models/auth';
import { PermissionEnum } from '../models/enums/auth-permissions-enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: IAuthInfo;

  constructor(
    private http: HttpClient,
    private permissionsService: NgxPermissionsService,
  ) {
    const token = localStorage.getItem('auth-token');
    if (token) {
      this.setUserInfo(token);
    } else {
      this.user = {
        email: null,
        isAuth: false,
        permissions: [],
        token: null,
      };
    }
  }

  public login(user: IUser): Observable<HttpResponse<void>> {
    return this.http
      .post<void>(`/api/login`, user, { observe: 'response' })
      .pipe(
        tap((resp) => {
          const token = resp.headers.get('Authorization') || '';
          this.setUserInfo(token);
        }),
      );
  }

  public setUserInfo(token: string): void {
    const decodeToken = jwtDecode<IDecodeJWT>(token);

    localStorage.setItem('auth-token', token);
    this.user = {
      email: decodeToken.email,
      isAuth: true,
      permissions: decodeToken.permissions || [],
      token: token,
    };

    const permissionsNames =
      this.user.permissions?.map((p) => PermissionEnum[p]) || [];
    this.permissionsService.loadPermissions(permissionsNames);
  }

  public clearUserInfo(): void {
    localStorage.clear();
    this.user.email = null;
    this.user.isAuth = false;
  }

  public changePassword(body: IChangePassword): Observable<any> {
    return this.http.put(`/api/profile/password`, body, {
      observe: 'response',
    });
  }
}
