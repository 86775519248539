import { FormControl, ValidatorFn } from '@angular/forms';

export function newFormControl<T>(
  value: T,
  validators?: ValidatorFn | ValidatorFn[] | null,
): FormControl<T> {
  return new FormControl(value, {
    validators,
    nonNullable: true,
  });
}

export function newUndefinedFormControl<T>(
  value: T,
  validators?: ValidatorFn | ValidatorFn[] | null,
): FormControl<T | undefined> {
  return new FormControl(value, {
    validators,
    nonNullable: true,
  });
}
