<form [formGroup]="form" (ngSubmit)="changePassword()">
  <mat-dialog-content class="mat-typography group-wrapper">
    <div class="login-wrapper">
      <div class="login-container">
        <h5 class="modal-title">Change password</h5>
        <mat-form-field>
          <mat-label>Enter your old password</mat-label>
          <input
            [type]="hide ? 'password' : 'text'"
            formControlName="oldPassword"
            matInput
            placeholder="******"
            required/>
          @if (form.controls.oldPassword.invalid) {
            <mat-error>Field is empty</mat-error>
          }
          <button
            mat-icon-button
            matSuffix
            type="button"
            (click)="clickEvent()"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Enter your new password</mat-label>
          <input
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            matInput
            placeholder="******"
            required
            (change)="form.controls.confirmPassword.updateValueAndValidity()"/>
          @if (form.controls.password.invalid) {
            <mat-error>Field is empty</mat-error>
          }
          <button
            mat-icon-button
            matSuffix
            type="button"
            (click)="clickEvent()"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Confirm password</mat-label>
          <input
            [type]="hide ? 'password' : 'text'"
            formControlName="confirmPassword"
            matInput
            placeholder="******"
            required/>
          @if (form.controls.confirmPassword.invalid) {
            <mat-error>Passwords mismatch</mat-error>
          }
          <button
            mat-icon-button
            matSuffix
            type="button"
            (click)="clickEvent()"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <div *ngIf="errMsg !== ''" class="err-field">
    <mat-error>{{ errMsg }}</mat-error>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="form.invalid || isLoading" type="submit" class="login-btn" mat-button>
      Change
    </button>
  </mat-dialog-actions>
</form>
