import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: HttpErrorResponse) => {
          if (err instanceof HttpErrorResponse) {
            const url = location.toString();
            if (err.status !== 401 || url.includes('/auth')) {
              return;
            }

            this.router
              .navigate(['/auth'])
              .then(() => this.authService.clearUserInfo());
          }
        },
      ),
    );
  }
}
