import { Component } from '@angular/core';

import { MainComponent } from './main/main.component';
import { BalanceService } from './services/balance.service';
import {ConfirmDialogComponent} from "./components/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [MainComponent, ConfirmDialogComponent],
  providers: [BalanceService],
})
export class AppComponent {
  title = 'Tower Admin Dashboard';
}
