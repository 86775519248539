<div *ngIf="authService.user.isAuth" class="app-header">
  <app-header></app-header>
</div>
<div *ngIf="authService.user.isAuth" class="container">
  <app-sidenav></app-sidenav>
</div>

<div
  *ngIf="authService.user.isAuth"
  class="content"
  [ngClass]="isSidebarVisible ? 'with-sidebar' : 'without-sidebar'">
  <router-outlet></router-outlet>
</div>

<div *ngIf="!authService.user.isAuth">
  <router-outlet></router-outlet>
</div>
