<div
  class="sidebar"
  [ngClass]="{ showSidebar: isSidebarVisible, hideSidebar: !isSidebarVisible }"
  [@fadeInOut]>
  <ul>
    <li>
      <a routerLinkActive="active" routerLink="/dashboard">
        <span class="menu-icon material-icons">dashboard</span>
        <span class="menu-text">Dashboard</span>
      </a>
    </li>
    <li>
      <a routerLink="/api-version" routerLinkActive="active">
        <span class="menu-text">Api version</span>
      </a>
    </li>
    <li *ngxPermissionsOnly="['SuperAdmin']">
      <a routerLink="/admins" routerLinkActive="active">
        <span class="menu-text">Admins</span>
      </a>
    </li>
    <li>
      <a routerLink="/stats" routerLinkActive="active">
        <span class="menu-text">Stats</span>
      </a>
    </li>
  </ul>
</div>
