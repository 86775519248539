import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionsGuard } from './services/permissions.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/api-version',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'admins',
    canActivate: [PermissionsGuard],
    data: {
      permissions: {
        only: 'SuperAdmin',
      },
    },
    loadComponent: () =>
      import('./pages/admins/admins.component').then((m) => m.AdminsComponent),
  },
  {
    path: 'api-version',
    loadComponent: () =>
      import('./pages/api-version-list/api-version-list.component').then(
        (m) => m.ApiVersionListComponent,
      ),
  },
  {
    path: 'api-version/:id',
    loadComponent: () =>
      import('./pages/api-version-list/api-version/api-version.component').then(
        (m) => m.ApiVersionComponent,
      ),
  },
  {
    path: 'api-version/:id/balance/:balance-id',
    loadComponent: () =>
      import(
        './pages/api-version-list/api-version/balance-details/balance-details.component'
      ).then((m) => m.BalanceDetailsComponent),
  },
  {
    path: 'api-version/history/:id',
    loadComponent: () =>
      import(
        './pages/api-version-list/api-version-history/api-version-history.component'
      ).then((m) => m.ApiVersionHistoryComponent),
  },
  {
    path: 'stats',
    loadComponent: () =>
      import('./pages/stats/stats.component').then((m) => m.StatsComponent),
  },
  {
    path: 'stats/:gameId/details',
    loadComponent: () =>
      import('./pages/stats/player-stats/player-stats.component').then(
        (m) => m.PlayerStatsComponent,
      ),
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./pages/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent,
      ),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [PermissionsGuard],
})
export class AppRoutingModule {}
