import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class PermissionsGuard {
  constructor(private ngxPermissionsService: NgxPermissionsService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.ngxPermissionsService.hasPermission(
      route.data[`permissions`]?.only,
    );
  }
}
