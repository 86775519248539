import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent, MatDialogRef,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import {
  MatError,
  MatFormField,
  MatInput,
  MatLabel,
  MatSuffix,
} from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import {newFormControl} from "../../../tools/new-form-control.tools";
import {ProgressBarService} from "../../../services/progress-bar.service";
import {UtilsService} from "../../../services/utils.service";
import {lastValueFrom} from "rxjs";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatIcon,
    MatIconButton,
    MatInput,
    ReactiveFormsModule,
    MatError,
    MatFormField,
    MatLabel,
    MatSuffix,
    NgIf,
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent implements OnInit {
  public isLoading = true;
  public hide = true;
  public errMsg: string = '';

  public form = this.fb.group({
    oldPassword: newFormControl('', [Validators.required]),
    password: newFormControl('', [Validators.required]),
    confirmPassword: newFormControl(
      '',
      [
        Validators.required,
        (control: AbstractControl<string>): ValidationErrors | null => {
          if (!control.value) {
            return null;
          }

          return this.form?.controls.password.value !== control.value
            ? { notMatch: true }
            : null;
        },
      ],
    ),
  });

  constructor(
    private authService: AuthService,
    private progressBarService: ProgressBarService,
    private toastrService: ToastrService,
    private utilsService: UtilsService,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
  }

  clickEvent() {
    this.hide = !this.hide;
  }

  async changePassword(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.progressBarService.start();
    const body = {
      newPassword: this.form.controls.password.value,
      oldPassword: this.form.controls.oldPassword.value,
    }

    try {
      await lastValueFrom(this.authService.changePassword(body));
      this.dialogRef.close();
      this.toastrService.success('Password changed successfully.');
    } catch (e) {
      this.errMsg = this.utilsService.getErrorText(e)
      this.toastrService.error(this.utilsService.getErrorText(e))
    }

    this.progressBarService.stop();
    this.isLoading = false;
  }
}
