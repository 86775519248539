import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  public mapAnyToMapString(
    source: Record<string, any>,
  ): Record<string, string> {
    source = { ...source };
    Object.keys(source).forEach((filterKey) => {
      if (!source[filterKey]) {
        return;
      }

      if (source[filterKey].toJSON) {
        source[filterKey] = source[filterKey].toJSON();
        return;
      }

      source[filterKey] = source[filterKey].toString();
    });
    return source;
  }

  public removeEmptyFields(obj: any) {
    Object.keys(obj).forEach((key) => {
      const v = (obj as any)[key];
      if (v === undefined || v === null || v === '') {
        delete (obj as any)[key];
      }
    });

    return obj;
  }

  getErrorText(e: any, priorityMessage?: boolean): string {
    if (e.error?.messageCode === 'InvalidParams') {
      return e.error.message
        .map(
          (message: { field: string; message: string[] }) =>
            `${message.field}: ${message.message.join(' and ')}`,
        )
        .join('\n');
    }

    if (typeof e === 'string') {
      return e;
    }
    if (typeof e.message === 'string' && !e.error) {
      return e.message;
    }
    if (typeof e.error === 'string') {
      return e.error;
    }

    let errorText: any;

    if (typeof e.error === 'object') {
      if (
        Array.isArray(e.error.message) &&
        typeof e.error.message[0] === 'string'
      ) {
        return e.error.message[0];
      }

      const err = e.error;
      if (priorityMessage && (err.m || err.msg || err.message)) {
        return err.m || err.msg || err.message;
      }

      errorText =
        e.error.error ||
        e.error.err ||
        e.error.e ||
        e.error.m ||
        e.error.msg ||
        e.error.message;
    }

    if (!errorText && typeof e.message === 'object') {
      errorText =
        e.message.error ||
        e.message.err ||
        e.message.e ||
        e.message.m ||
        e.message.msg ||
        e.message.message;
    }

    if (typeof errorText === 'object') {
      errorText = 'Some error happened';
    }

    return errorText;
  }
}
