import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WorkloadIndicator {
  public count = new BehaviorSubject(0);
  public isLoading = this.count.pipe(map((v) => v > 0));

  public start() {
    this.count.next(this.count.value + 1);
  }

  public stop() {
    const count = this.count.value;
    if (count === 0) {
      return;
    }

    this.count.next(count - 1);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService extends WorkloadIndicator {
  constructor(private router: Router) {
    super();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.start();
      }
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.stop();
      }
    });
  }
}
